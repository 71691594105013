<template>
  <div>
    <r-plotind
      v-show="selectedItem==='r_plotind'"
      ref="plotind"
      :selectedDataset="selectedDataset"
      :datasetName="PCAobjectName"
      :nbCP="nAxes" />
    <r-plotvar
      ref="plotvar"
      v-show="selectedItem==='r_plotvar'"
      :selectedDataset="selectedDataset"
      :datasetName="PCAobjectName"
      :nbCP="nAxes" />
    <r-extract-obj
      v-if="selectedItem==='r_extract_obj'"
      ref='extractobj'
      :extractSuffix="datasetUserName"
      :datasetName="PCAobjectName"
      datasetPrefix="PCA"
      :nbCP="nAxes" />
    <r-pca-step
    ref='rpca'
    v-show="selectedItem==='r_famd'"
    @is-computed="isComputed"
    :analysisObj="analysisObj"
    />
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'
import { useUtilsStore } from '@/stores/utils'

export default {
  name: 'RPCA',
  components: {
    'r-plotind': () => import('@/components/ranalyses/RPlotind.vue'),
    'r-plotvar': () => import('@/components/ranalyses/RPlotvar.vue'),
    'r-extract-obj': () => import('@/components/ranalyses/RExtractObj.vue'),
    'r-pca-step': () => import('@/components/ranalyses/RPCAStep.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          name: 'r_famd',
          title: 'Run PCA',
          disabled: false
        },
        {
          name: 'r_plotind',
          title: 'Explore individuals',
          disabled: true
        },
        {
          name: 'r_plotvar',
          title: 'Explore variables',
          disabled: true
        },
        {
          name: 'r_extract_obj',
          title: 'Extract new data',
          disabled: true
        }
      ],
      selectedDataset: null,
      PCAobjectName: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    const utilsStore = useUtilsStore()
    return { workspaceStore, menuStore, utilsStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    datasets: function () {
      return this.workspaceStore.datasets
    },
    nAxes: function () {
      let nAxes = 0
      let pcaAnalysis = this.analyses.find(obj => obj.object_name === this.PCAobjectName)
      if (pcaAnalysis !== undefined) {
        nAxes = Number.parseInt(pcaAnalysis.meta.func_args.ncp)
      }
      return nAxes
    },
    datasetUserName: function () {
      return this.datasets.find(obj => obj.object_name === this.selectedDataset).user_name
    }
  },
  methods: {
    enableMenu: function () {
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
    },
    isComputed: function (data) {
      this.reset()
      this.PCAobjectName = data.ObjectName
      this.selectedDataset = data.datasetName
      this.enableMenu()
    },
    setResults: function (data) {
      let analyse = this.analyses.filter(a => a.object_name === data.ObjectName)
      this.$refs.rpca.setResults(data)
      this.$refs.rpca.afterRun(data)
      this.PCAobjectName = data.ObjectName
      this.selectedDataset = analyse[0].origin_dataset
      this.resultsLoaded = true
      this.enableMenu()
    },
    reset: function () {
      this.$refs.plotind.reset()
      this.$refs.plotvar.reset()
      this.currentResult = 'plots'
      this.menuItems[1].disabled = true
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
    }
  },
  mounted () {
    if (this.analysisObj !== null) {
      this.PCAobjectName = this.analysisObj.object_name
      this.selectedDataset = this.analysisObj.datasetName
    }
  }
}
</script>
<style scoped>
  .b-tabs .tab-content {
    padding: 0rem;
  }
</style>
